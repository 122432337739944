import React from "react";
import { View, Image } from "@aws-amplify/ui-react";
import { loadingOverlay } from "aws-amplify";

const Header1 = ({ tokens }) => {
    return (
        <View textAlign="center" padding={tokens.space.medium}>
            <Image alt="RSNA logo" src="logo.svg" maxWidth="220px" />
        </View>
    );
};

export default Header1;
