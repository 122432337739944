/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:64ffbace-aec5-43c1-8aa2-8aae6f070c59",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_5qMNc2y2r",
    "aws_user_pools_web_client_id": "mfo50rgt0948948h9gr9960m6",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "ON",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "s3uploadunzipd6133dee5ce14f52aaafbfd42a81b6a1143519-prodmidrc",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_content_delivery_bucket": "s3uploadunzip-20221124121048-hostingbucket-prodmidrc",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d1wcmec7a8mpcr.cloudfront.net",
    "aws_appsync_graphqlEndpoint": "https://al3cnvmj5zdhtfonp7zgefybxu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
